import React, { useState, useEffect } from 'react';
import HeroSection from '../components/HeroSection';
import { AboutSection, DetermineSection, WhySection, AdmissionSection, DepartmentSection, TCSection } from '../components/Section';
import SeparatorComponent from '../components/SeparatorComponent';
import { Modal, Button } from 'react-bootstrap';

const HomePage = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(true);
    }, []);

    const handleClose = () => setShowModal(false);

    return (
        <>
            {/* Admission Popup */}
            <Modal show={showModal} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title> कक्षा छठवीं में प्रवेश प्राप्त करने के लिए यहाँ क्लिक करें</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p>प्रवेश के लिए नीचे दिए गए बटन पर क्लिक करें:</p>
                    <a 
                        href="https://www.tribal.mp.gov.in/mptaasschool/SchoolStudent/PreExamRegistration/RegistrationForm" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <Button variant="primary" size="lg">👉 क्लिक करें</Button>
                    </a>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        बंद करें
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Sections */}
            <HeroSection />
            <SeparatorComponent />
            <WhySection />
            <SeparatorComponent />
            <TCSection />
            <SeparatorComponent />
            <AboutSection />
            <SeparatorComponent />
            <AdmissionSection />
            <SeparatorComponent />
            <DepartmentSection />
            <SeparatorComponent />
            <DetermineSection />
            <SeparatorComponent />
        </>
    );
};

export default HomePage;
